import compact from 'lodash/compact'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import tw, { css, styled } from 'twin.macro'
import { ReactComponent as Caret } from '../images/icon-caret-down-lg.svg'
import { global } from '../styles/global'
import GatsbyLink from './link'

const ColumnHeader = styled.div`
  ${tw`
    cursor-pointer flex justify-between items-center text-lg font-medium leading-subtitle
    tracking-subtitle text-secondary-500 lg:(text-base cursor-auto)
  `}
`

const Link = (props) => {
  return (
    <GatsbyLink
      style={[
        tw`text-2xl font-light text-white leading-h5 tracking-h4`,
        global`base.hover-underline`(tw`bg-white`, tw`h-px`),
      ]}
      localized
      {...props}
    />
  )
}

const MobileDivider = styled.div`
  left: calc((100vw - 48px) / -19.2);
  right: calc((100vw - 48px) / -19.2);
  ${tw`absolute bottom-0 h-px bg-white bg-opacity-20 lg:hidden`};
`

const Footer = ({ logo, linkGroups, legalLabel, socialMedia, copyright }) => {
  const [activeColumn, setActiveColumn] = useState(-1)
  const linkHeaders = useMemo(() => linkGroups.map((group) => group.label))
  const links = useMemo(() => linkGroups.map((group) => compact(group.subLinks)))

  return (
    <footer
      css={tw`relative bottom-0 flex flex-col w-full overflow-hidden -z-10 bg-primary-500 lg:sticky`}
    >
      <section css={tw`pt-14 lg:py-14`}>
        <nav css={[global`layout.container`, global`layout.grid`]}>
           {links &&
            links.map((linkGroup, i) => (
              <div
                key={linkHeaders[i]}
                css={[
                  tw`relative col-span-4 first-of-type:pt-0 py-6 md:col-span-8 lg:(py-0 col-span-2 first-of-type:col-span-3) xl:(col-span-3 first-of-type:col-span-4)`,
                  activeColumn === i &&
                    css`
                      ul {
                        ${tw`max-h-screen`}
                      }
                    `,
                ]}
              >
                <ColumnHeader
                  onClick={() => (activeColumn === i ? setActiveColumn(-1) : setActiveColumn(i))}
                >
                  {linkHeaders[i]}
                  <Caret
                    css={[
                      tw`flex-none block text-white transition duration-300 ease-in-out fill-current stroke-0 lg:hidden`,
                      activeColumn === i &&
                        css`
                          ${tw`text-secondary-500`}
                          transform: scaleY(-1);
                        `,
                    ]}
                  />
                </ColumnHeader>
                <ul
                  css={tw`max-h-0 overflow-hidden transition-all duration-300 ease-linear flex flex-col lg:(max-h-full mt-5)`}
                >
                  {linkGroup.map((l) => (
                    <li
                      key={l.label}
                      css={tw`mb-4 first-of-type:mt-5 lg:(first-of-type:mt-1 mb-2)`}
                    >
                      <Link to={l.link}>{l.label}</Link>
                    </li>
                  ))}
                </ul>
                <MobileDivider />
              </div>
            ))}
          <GatsbyLink
            to="/"
            style={tw`flex items-start justify-start col-span-4 row-start-1 mb-14 md:col-span-8 lg:(mb-0 justify-end row-start-auto col-start-11 col-end-13) xl:(col-start-14 col-end-17)`}
            localized        
          >
            <img
              css={[tw`w-full`, { maxWidth: '138.65px' }]}
              src={get(logo, 'url')}
              alt={get(logo, 'alt')}
              title={get(logo, 'title')}
              width={get(logo, 'width')}
              height={get(logo, 'height')}
            />
          </GatsbyLink>
        </nav>
      </section>
      <section css={tw`border-t border-white pt-5 pb-7 lg:(pt-6 pb-18) border-opacity-20`}>
        <div css={[global`layout.container`, global`layout.grid`]}>
          <div
            css={tw`flex flex-col col-span-4 mb-4 md:col-span-8 lg:(flex-row items-center col-span-8) xl:col-span-12`}
          >
            <ColumnHeader css={tw`text-base lg:mr-8`}>{legalLabel}</ColumnHeader>
{/*             <div css={tw`flex flex-col mt-2 space-y-4 lg:(mt-0 flex-row space-y-0 space-x-8)`}>
              {legal.map((l, i) => (
                <Link key={i} to={l.link} css={tw`text-base font-normal`}>
                  {l.label}
                </Link>        
              ))}              
            </div> 
            <div css={tw`flex flex-col mt-2 space-y-4 lg:(mt-0 flex-row space-y-0 space-x-8)`}>
            {legal.map((l, i) => (
                <Link key={i} to={l.link} css={tw`text-base font-normal`}>
                  {l.label}
                </Link>          
              ))}                         
            </div> */}
          </div>
          {socialMedia && (
            <div
              css={tw`flex flex-row mt-8 mb-4 col-span-2
              md:col-span-4 lg:(mt-0 col-span-4 justify-end items-center)`}
            >
              {socialMedia.map((s) => (
                <GatsbyLink
                  key={s.title}
                  to={s.accountLink.link}
                  style={tw`mr-12 last-of-type:mr-0`}
                >
                  <img
                    src={s.image.url}
                    title={s.title}
                    alt={s.alt}
                    loading="lazy"
                    css={tw`max-h-social-icon`}
                    width={s.width}
                    height={s.height}
                  />
                </GatsbyLink>
              ))}
            </div>
          )}
          <span
            css={tw`col-span-4 text-sm leading-4 text-white text-opacity-50 md:col-span-8 lg:col-span-12 xl:col-span-16`}
          >
            {copyright}
          </span>
        </div>
      </section>
    </footer>
  )
}

Footer.propTypes = {
  logo: PropTypes.object,
  linkGroups: PropTypes.arrayOf(PropTypes.object),
  socialMedia: PropTypes.arrayOf(PropTypes.object),
  legalLabel: PropTypes.string,
  legal: PropTypes.arrayOf(PropTypes.object),
  copyright: PropTypes.string,
}

export default Footer
